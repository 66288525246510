import React from 'react';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import WistiaVideo from 'components/Core/WistiaVideo';
import WebsiteImage from 'components/WebsiteImage';
import ContentfulCallToAction from 'components/Contentful/ContentfulCallToAction';
import { colorTypeHelper } from 'utils/colorUtility';

import { Container } from './styles';

const Router = ({ entries = [] }) => {
  const title = entries.filter((entry) => entry.templateId === 'title')?.[0];
  const featuredVideo = entries.filter((entry) => entry.templateId === 'featuredVideo')?.[0];
  const featuredImage = entries.filter((entry) => entry.templateId === 'featuredImage')?.[0];
  const featuredComment = entries.filter((entry) => entry.templateId === 'featuredComment')?.[0];
  const quote = entries.filter((entry) => entry.templateId === 'quote')?.[0];
  const person = entries.filter((entry) => entry.templateId === 'person')?.[0];
  const logoImage = entries.filter((entry) => entry.templateId === 'logo')?.[0];
  const cta = entries.filter((entry) => entry.templateId === 'cta')?.[0];

  const styles = entries.filter((entry) => entry.templateId === 'section')?.[0]?.styles;
  const backgroundColor = styles?.backgroundColor;
  const customCss = styles?.customStyling?.customStyling;

  return (
    <Container
      className="router--container"
      backgroundColor={colorTypeHelper[backgroundColor]}
      customCss={customCss}
    >
      <ContentfulRichText className="router--heading" richText={title} />
      <div className="router--content">
        <div className="router--featured-container">
          <div className="router--media-container">
            {featuredVideo ? (
              <WistiaVideo
                {...featuredVideo}
                size={featuredVideo?.size || 'Medium'}
              />
            ) : (featuredImage ? (
              <WebsiteImage {...featuredImage} />
            ) : null)}
          </div>
          <div className="router--comment-container">
            <ContentfulRichText className="router--comment" richText={featuredComment} />
          </div>
        </div>
        <div className="router--copy-container">
          <div className="router--copy-content">
            <ContentfulRichText className="router--quote" richText={quote} />
            <ContentfulRichText className="router--person" richText={person} />
            <WebsiteImage className="router--logo" {...logoImage} />
            <ContentfulCallToAction className="router--cta" {...cta} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Router;
