import styled from 'styled-components';

import quoteIcon from 'src/images/quote-icon.svg';

const Container = styled.div`
  background: ${({ backgroundColor }) => `var(${backgroundColor})`};
  max-width: 1252px;
  padding: 60px 28px;
  margin: 0 auto;

  .router--heading {
    h2 {
      font-size: var(--font-size-h1);
      text-align: center;
    }
  }

  .router--content {
    display: flex;
    align-items: center;
    gap: 0 60px;
    margin: 25px 0 0;

    h2,
    h3,
    p {
      margin: 0;
    }

    .router--featured-container {
      flex: 484;
      height: fit-content;
      position: relative;

      .router--comment-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: var(--darkest);
        width: 50%;
        height: 50%;
        max-width: 200px;
        max-height: 200px;

        h2,
        p {
          text-align: center;
          color: var(--white);
        }
      }
    }

    .router--copy-container {
      flex: 652;
      padding: 32px;

      .router--copy-content {
        display: flex;
        flex-direction: column;

        .router--quote {
          position: relative;
          padding: 36px 0 0;

          &:before {
            content: '';
            position: absolute;
            width: 20px;
            height: 16px;
            background-image: url('${quoteIcon}');
            background-repeat: no-repeat;
            top: 0;
            left: 0;
          }

          h3 {
            color: var(--dark);
          }
        }

        .router--person {
          margin: 20px 0 15px;

          p {
            font-size: var(--font-size-p-lg);
            color: var(--darkest);
          }
        }

        .router--logo {
          img {
            width: auto;
            height: 75px;
          }
        }

        .router--cta {
          border: 1px solid var(--darkest);
          color: var(--darkest);
          background: var(--white);
          height: 62px;
          margin-top: 30px;

          &:hover {
            color: var(--white);
            background: var(--darkest);
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .router--content {
      gap: 20px 40px;

      .router--copy-container {
        padding: 20px 0 20px 20px;
      }
    }
  }

  @media (max-width: 767px) {
    .router--heading {
      h2 {
        font-size: var(--font-size-mobile-h1);
      }
    }

    .router--content {
      flex-direction: column;

      .router--featured-container {
        flex: auto;

        .router--comment-container {
          width: 170px;
          height: 130px;
        }
      }

      .router--copy-container {
        flex: auto;
        padding: 0;

        .router--copy-content {
          .router--person {
            p {
              font-size: var(--font-size-mobile-p-lg);
            }
          }

          .router--logo {
            img {
              height: 60px;
            }
          }

          .router--cta {
            height: 54px;
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    padding: 60px 20px;
  }

  ${({ customCss }) => customCss};
`;

export { Container };
